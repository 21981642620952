import { Amplify, Hub } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useRouter } from 'next/router';
import '../styles/globals.css';
import awsExports from '../aws-exports';
import { useEffect, useState, createContext } from 'react';
import { getUser, validateUser } from '../queries/backendQueries';
import Layout from '../components/shared/layout';
import IntroductionModal from '../components/shared/introductionModal';
import Script from 'next/script';

Amplify.configure({
  ...awsExports,
  ssr: false,
  cookieStorage: { domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN ?? 'localhost' }
});

export const UserContext = createContext({
  user: null,
  updateUser: () => {}
});

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  const [user, setUser] = useState();
  const [showModal, setShowModal] = useState(false);

  function launchValidateUser(authUser, idToValidate = null) {
    console.log('validate');
    const idSso = authUser.idSso;
    validateUser(idSso, idToValidate || idSso)
      .then(() => updateUser())
      .catch((err) => console.log(err));
  }

  function updateUser() {
    Auth.currentAuthenticatedUser({
      bypassCache: true
    })
      .then((result) =>
        getUser(result.username)
          .then((response) => {
            setUser(response.result[0]);
          })
          .catch((err) => console.log(err))
      )
      .catch(() => setUser(null));
  }

  useEffect(() => {
    const idToValidate = router.query.idToValidate;
    if (idToValidate) {
      localStorage.setItem('ID_TO_VALIDATE', idToValidate);
    }

    Auth.currentAuthenticatedUser()
      .then((result) => getUser(result.username))
      .then((response) => {
        setUser(response.result[0]);
        if (response.result[0].userStatus === 'CREATED' && response.result[0].idStructure !== null)
          launchValidateUser(response.result[0]);
      })
      .catch(() => {});

    if (!localStorage.getItem('ALREADY_VISITED')) {
      setShowModal(true);
      localStorage.setItem('ALREADY_VISITED', true);
    }
  }, [router.query]);

  useEffect(() => {
    const listener = (data) => {
      switch (data.payload.event) {
        case 'signOut':
          setUser(undefined);
          break;
      }
    };

    Hub.listen('auth', listener);
  }, []);

  return (
    <UserContext.Provider value={{ user, updateUser }}>
      {/* Début de la mention OneTrust de consentement aux cookies du domaine : prod.breeder-connect.io */}

      <Script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        charSet="UTF-8"
        data-domain-script={process.env.NEXT_PUBLIC_ONETRUST_SCRIPT}
        strategy="beforeInteractive"
      />
      <Script strategy="beforeInteractive">{`function OptanonWrapper() {}`}</Script>
      <Script strategy="lazyOnload">
        {`
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = 'e2518f16-b39f-43a2-91a0-a0ae301a1639';

        (function () {
          const d = document;
          const s = d.createElement('script');

          s.src = 'https://client.crisp.chat/l.js';
          s.async = 1;
          d.getElementsByTagName('head')[0].appendChild(s);
        })();
        `}
      </Script>
      {/* Fin de la mention OneTrust de consentement aux cookies du domaine : prod.breeder-connect.io */}
      <Layout>
        <Component {...pageProps} />
        <IntroductionModal show={showModal} handleClose={() => setShowModal(false)} />
      </Layout>
    </UserContext.Provider>
  );
}

MyApp.propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.object
};

export default MyApp;
