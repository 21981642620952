import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'react-bootstrap';
import Link from 'next/link';

const BtnContainer = styled(Button)`
  min-width: 215px;
  height: 48px;
  border-radius: 8px;
  background-image: linear-gradient(to bottom, #ee8b0d, #e49a3b);
  margin: 20px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.33;
  color: #ffffff;
  border: none;

  .spinner-border {
    margin-left: 10px;
  }

  a {
    color: #ffffff;
  }

  a:hover {
    color: #ffffff;
  }

  img {
    max-height: 20px;
    max-width: 166;
    cursor: pointer;
  }
`;

const Main = ({
  onButtonClick,
  url,
  text,
  style,
  disabled = false,
  spinner = false,
  targetBlank = false,
  image = false
}) => {
  return url ? (
    <BtnContainer size="lg" style={style} disabled={disabled}>
      {!targetBlank ? (
        <Link href={url}>{text}</Link>
      ) : (
        <a target="_blank" rel="noreferrer" href={url}>
          {text}
          {image && <img src={image.url} alt={image.alt} />}
        </a>
      )}
      {spinner ? (
        <Spinner size="sm" as="span" animation="border" role="status" aria-hidden="true" variant="warning" />
      ) : (
        ''
      )}
    </BtnContainer>
  ) : (
    <BtnContainer onClick={onButtonClick} size="lg" style={style} disabled={disabled}>
      {text}
      {image ? <img src={image.url} alt={image.alt} /> : ''}
      {spinner ? (
        <Spinner size="sm" as="span" animation="border" role="status" aria-hidden="true" variant="warning" />
      ) : (
        ''
      )}
    </BtnContainer>
  );
};

Main.propTypes = {
  onButtonClick: PropTypes.func,
  url: PropTypes.string,
  text: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  spinner: PropTypes.bool,
  targetBlank: PropTypes.bool,
  image: PropTypes.object,
  rel: PropTypes.string
};

export default Main;
