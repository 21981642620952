/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:29b5e6d3-1b49-4c4f-ada1-f87123ac12c4",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_zTy2BDaqW",
    "aws_user_pools_web_client_id": "3ifndhbh2r8hb4h68c6uc9aieb",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "ConnectEditosApi",
            "endpoint": "https://gzhctqweoi.execute-api.eu-west-1.amazonaws.com/develop",
            "region": "eu-west-1"
        },
        {
            "name": "ConnectCountriesApi",
            "endpoint": "https://yrffkurcql.execute-api.eu-west-1.amazonaws.com/develop",
            "region": "eu-west-1"
        },
        {
            "name": "ConnectDomainsApi",
            "endpoint": "https://hp6slvhfb2.execute-api.eu-west-1.amazonaws.com/develop",
            "region": "eu-west-1"
        },
        {
            "name": "ConnectJobsApi",
            "endpoint": "https://ndbu11caje.execute-api.eu-west-1.amazonaws.com/develop",
            "region": "eu-west-1"
        },
        {
            "name": "ConnectUsersApi",
            "endpoint": "https://7i2aewtup4.execute-api.eu-west-1.amazonaws.com/develop",
            "region": "eu-west-1"
        },
        {
            "name": "ConnectResourcesApi",
            "endpoint": "https://x34hmve0rj.execute-api.eu-west-1.amazonaws.com/develop",
            "region": "eu-west-1"
        },
        {
            "name": "ConnectPartnersApi",
            "endpoint": "https://aylkj9qbyb.execute-api.eu-west-1.amazonaws.com/develop",
            "region": "eu-west-1"
        },
        {
            "name": "ConnectStripeApi",
            "endpoint": "https://rbzuk9za90.execute-api.eu-west-1.amazonaws.com/develop",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "connect-bucket162926-develop",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
