import API from '@aws-amplify/api-rest';

export async function getEdito() {
  try {
    return API.get('ConnectEditosApi', '/editos/active', null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function getUser(id) {
  try {
    return API.get('ConnectUsersApi', '/users/' + id, null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function getUserByMail(mail) {
  try {
    return API.get('ConnectUsersApi', '/users/mail/' + mail, null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function getExperts() {
  try {
    return API.get('ConnectUsersApi', '/users/expert', null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function getThematicResources(thematicId) {
  try {
    return API.get('ConnectResourcesApi', '/resources/preview/thematic/' + thematicId, null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function getRelatedResources(id) {
  try {
    return API.get('ConnectResourcesApi', '/resources/more/' + id, null);
  } catch (error) {
    console.log(error);
  }
}

export async function getResource(id) {
  try {
    return API.get('ConnectResourcesApi', '/resources/' + id, null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function getResourceWithSlug(slug) {
  try {
    return API.get('ConnectResourcesApi', '/resources/slug/' + slug, null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function getResourcesPreview() {
  try {
    return API.get('ConnectResourcesApi', '/resources/preview', null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function getResourcesPreviewWithIds(uuids) {
  let uuidsString = '';
  const array = [].concat(uuids);
  // Conversion du tableau en une chaine de caractère 'uuid','uuid',
  array.map((uuid) => (uuidsString += "'" + uuid + "',"));
  // Suppression de la virgule en trop en fin de chaine
  uuidsString = uuidsString.slice(0, -1);
  try {
    return API.get('ConnectResourcesApi', '/resources/preview/' + uuidsString, null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function sendEmail(recipient, subject, message, sender, name, page) {
  try {
    return API.put('ConnectUsersApi', '/mails/send', {
      body: {
        recipient,
        subject,
        message: message || 'message',
        sender: sender || 'sender',
        name,
        page
      }
    });
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function sendFormationEmail(recipient, subject, name, formation, slot) {
  try {
    return API.put('ConnectUsersApi', '/mails/send', {
      body: {
        recipient,
        subject,
        name,
        message: 'message',
        sender: 'sender',
        formation,
        slot
      }
    });
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function sendEmailRaw(data) {
  try {
    return API.put('ConnectUsersApi', '/mails/sendRaw', {
      body: data
    });
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function sendContactEmail(recipient, subject, message, phone, sender, name, page, appointments) {
  try {
    return API.put('ConnectUsersApi', '/mails/send', {
      body: {
        recipient,
        subject,
        message: message || 'message',
        phone,
        sender: sender || 'sender',
        name,
        page,
        appointments
      }
    });
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function createUser(data) {
  try {
    return API.put('ConnectUsersApi', '/users', {
      body: {
        structureName: data.structure,
        postalCode: data.postalCode,
        city: data.city,
        idCountry: data.idCountry,
        domains: data.domains,
        lastName: data.lastName,
        firstName: data.firstName,
        avatar: '',
        email: data.email,
        idJob: data.idJob,
        author: data.firstName + ' ' + data.lastName
      }
    });
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function getCountries() {
  try {
    return API.get('ConnectCountriesApi', '/countries', null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function getJobs() {
  try {
    return API.get('ConnectJobsApi', '/jobs', null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function getDomains() {
  try {
    return API.get('ConnectDomainsApi', '/domains', null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function getServices() {
  try {
    return API.get('ConnectUsersApi', '/services', null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function getServicesHomePage() {
  try {
    return API.get('ConnectUsersApi', '/services/homePage', null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function getServicesServicePage() {
  try {
    return API.get('ConnectUsersApi', '/services/servicePage', null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function getCategories() {
  try {
    return API.get('ConnectUsersApi', '/services/categories', null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function getServiceWithSlug(slug) {
  try {
    return API.get('ConnectUsersApi', '/services/slug/' + slug, null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function updateUser(data, idUser) {
  try {
    return API.post('ConnectUsersApi', '/users/' + idUser, {
      body: {
        lastName: data.lastName,
        firstName: data.firstName,
        avatar: data.avatar,
        idJob: data.idJob,
        author: data.firstName + ' ' + data.lastName
      }
    });
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function updateAvatar(data, idUser) {
  try {
    return API.post('ConnectUsersApi', '/users/' + idUser + '/avatar', {
      body: {
        avatar: data.result,
        author: data.firstName + ' ' + data.lastName
      }
    });
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function subscribe(userId, channelId) {
  try {
    return API.post('ConnectUsersApi', '/users/' + userId + '/channel/' + channelId, null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function unsubscribe(userId, channelId) {
  try {
    return API.del('ConnectUsersApi', '/users/' + userId + '/channel/' + channelId, null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function verifyToken(token) {
  try {
    return API.put('ConnectUsersApi', '/users/recaptcha/verification', {
      body: {
        token
      }
    });
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function validateUser(ownerId, userId) {
  try {
    return API.post('ConnectUsersApi', '/users/' + ownerId + '/validate/' + userId);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function saveEcoRegime(data) {
  try {
    return API.post('ConnectUsersApi', '/oads/save', {
      body: data
    });
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function getEcoRegimeSimulations(userId) {
  try {
    return API.get('ConnectUsersApi', '/oads/simulations/' + userId, null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function deleteEcoRegimeSimulation(simulationId) {
  try {
    return API.del('ConnectUsersApi', '/oads/simulations/' + simulationId, null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function getReferences() {
  try {
    return API.get('ConnectUsersApi', '/oads/references', null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function calculateFood(data) {
  try {
    return API.put('ConnectUsersApi', '/oads/food/calculate', {
      body: data
    });
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function calculateBreeding(data) {
  try {
    return API.put('ConnectUsersApi', '/oads/breeding/calculate', {
      body: data
    });
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function calculateEcoRegime(data) {
  try {
    return API.put('ConnectUsersApi', '/oads/eco-regime/calculate', {
      body: data
    });
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function calculateLiners(data) {
  try {
    return API.put('ConnectUsersApi', '/oads/liners/calculate', {
      body: data
    });
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function calculateBio(data) {
  try {
    return API.put('ConnectUsersApi', '/oads/bio/calculate', {
      body: data
    });
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function calculateNutritional(data) {
  try {
    return API.put('ConnectUsersApi', '/oads/nutritionalImpact/calculate', {
      body: data
    });
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function calculateImr(data) {
  try {
    return API.put('ConnectUsersApi', '/oads/imr/calculate', {
      body: data
    });
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function getSurvey() {
  try {
    return API.get('ConnectUsersApi', '/surveys', null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function canVote(userId, surveyId) {
  try {
    return API.get('ConnectUsersApi', '/surveys/canVote/' + userId + '/' + surveyId, null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function registerVote(userId, propositionId, closed) {
  try {
    return API.post('ConnectUsersApi', '/surveys/registerVote/' + userId + '/' + propositionId, {
      body: {
        closedSurvey: closed
      }
    });
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function addProposition(userId, surveyId, propositionText) {
  try {
    return API.post('ConnectUsersApi', '/surveys/addProposition/' + userId + '/' + surveyId, {
      body: {
        proposition: propositionText
      }
    });
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function getRequests(userId) {
  try {
    return API.get('ConnectUsersApi', '/users/' + userId + '/request', null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function subscriptionRequest(userId, channelId) {
  try {
    return API.post('ConnectResourcesApi', '/resources/' + userId + '/request/' + channelId);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function validateRequest(userId, channelId, valid) {
  try {
    return API.put('ConnectResourcesApi', '/resources/' + userId + '/request/' + channelId, {
      body: {
        status: valid ? 'ACCEPTE' : 'REFUSE'
      }
    });
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function getFolders() {
  try {
    return API.get('ConnectUsersApi', '/folders', null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function getThresholds() {
  try {
    return API.get('ConnectUsersApi', '/oads/thresholds', null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function getAmounts() {
  try {
    return API.get('ConnectUsersApi', '/oads/amounts', null);
  } catch (error) {
    console.log(error.response.data.error);
  }
}

export async function calculateService(data) {
  try {
    return API.put('ConnectUsersApi', '/services/servicesCalcul', {
      body: data
    });
  } catch (error) {
    console.log(error);
  }
}

export async function serviceGeneratePDF(data) {
  try {
    return API.put('ConnectUsersApi', '/services/generatePDF', {
      body: data
    });
  } catch (error) {
    console.log(error);
  }
}
