import React from 'react';
import { initGA, logPageView } from '../../tools/analytics';
import PropTypes from 'prop-types';

export default class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ga4: null,
      error: false
    };
    initGA()
      .then((ga4) => this.setState({ ga4: ga4 }))
      .catch((err) => {
        this.setState({ error: true });
        console.log(Object.values(err));
      });
  }

  componentDidUpdate() {
    let ga4 = this.state.ga4;
    if (!ga4 && !this.state.error) {
      initGA()
        .then((res) => {
          ga4 = res;
          this.setState({ ga4: res });
        })
        .catch((err) => {
          this.setState({ error: true });
          console.log(err);
        });
    }
    if (ga4 && !this.state.error) {
      logPageView(ga4);
    }
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

Layout.propTypes = {
  children: PropTypes.any
};
