import GA4React from 'ga-4-react';

export const initGA = () => {
  console.log('GA init');
  const ga4react = new GA4React(process.env.NEXT_PUBLIC_ANALYTICS_CODE);
  return ga4react.initialize();
};
export const logPageView = (ga4) => {
  console.log(`Logging pageview for ${window.location.pathname}`);
  ga4.pageview(window.location.pathname);
};
